import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { useSpring, animated } from '@react-spring/web';
import { ContainerColumn, ContainerRow, SurfaceColumn } from '@components/styled';
import OverlayContent from './OverlayContent';
import getContainerStyles from './styles';
import PortfolioBadge from './PortfolioBadge';
const Container = styled(ContainerColumn, { shouldForwardProp: (propName) => isPropValid(propName) })((props) => ({
    width: getContainerStyles(props).byViewType[props.viewType].width,
    height: 'auto',
    position: 'relative',
}));
const OverlayContentContainer = animated(styled(ContainerRow)(() => ({
    position: 'absolute',
    width: '100%',
    height: 'auto',
    left: 0,
})));
const CardContainer = animated(styled(SurfaceColumn, { shouldForwardProp: (propName) => isPropValid(propName) })((props) => (Object.assign(Object.assign({ position: 'relative', boxSizing: 'border-box', zIndex: 1, flexDirection: props.viewType === 'card' ? 'column' : 'row', alignItems: props.viewType === 'card' ? 'baseline' : 'center', justifyContent: props.viewType === 'card' ? 'start' : 'space-between', width: getContainerStyles(props).byViewType[props.viewType].width, height: getContainerStyles(props).byViewType[props.viewType].height, padding: getContainerStyles(props).byViewType[props.viewType].padding, borderColor: getContainerStyles(props).byDisplayState[props.displayState].borderColor, borderRadius: getContainerStyles(props).byViewType[props.viewType].borderRadius, boxShadow: getContainerStyles(props).byVariant[props.variant].boxShadow, backgroundColor: getContainerStyles(props).byViewType[props.viewType].backgroundColor }, props.displayOverlay && ({
    outline: `2px solid ${getContainerStyles(props).byDisplayState[props.displayState].overlayColor}`,
    outlineOffset: '-2px',
    boxShadow: 'none',
})), props.isSelected && ({
    outline: `2px solid ${getContainerStyles(props).byDisplayState[props.displayState].overlayColorOnSelected}`,
    outlineOffset: '-2px',
    backgroundColor: getContainerStyles(props)
        .byDisplayState[props.displayState]
        .backgroundColorOnSelected,
    boxShadow: props.displayOverlay ? 'none' : `0px 2px 10px -6px ${props.theme.customColors.surface.outline}`,
})))));
const PortfolioContainer = ({ children, portfolio, variant, viewType, displayState, selected = false, disabled = false, alwaysDisplayOverlay = false, onClick = () => { }, onMouseEnter = undefined, onMouseLeave = undefined, className = undefined, isHovered: isForceHovered = false, }) => {
    const [isHovered, setIsHovered] = useState(false);
    const overlayBottomPosition = useMemo(() => {
        let bottomPosition = -26;
        if (viewType === 'card') {
            bottomPosition = -20;
        }
        return bottomPosition;
    }, [viewType]);
    const [{ opacity, bottom }, api] = useSpring(() => ({
        opacity: 0,
        bottom: 0,
        config: key => {
            if (key === 'opacity') {
                return {
                    duration: 100,
                };
            }
            return {
                mass: 1,
                tension: 711,
                friction: 40,
            };
        },
    }), []);
    const onMouseEnterHandler = () => {
        setIsHovered(true);
        api.start({
            from: { opacity: 0, bottom: 0 },
            to: { opacity: 1, bottom: 1 },
        });
        if (onMouseEnter) {
            onMouseEnter(portfolio.id);
        }
    };
    const onMouseLeaveHandler = () => {
        setIsHovered(false);
        api.start({
            from: { opacity: 1, bottom: 1 },
            to: { opacity: 0, bottom: 0 },
        });
        if (onMouseLeave) {
            onMouseLeave(portfolio.id);
        }
    };
    const displayOverlay = alwaysDisplayOverlay ||
        (!disabled && !selected && isHovered) ||
        isForceHovered;
    return (_jsxs(Container, { viewType: viewType, onClick: onClick, onMouseEnter: onMouseEnterHandler, onMouseLeave: onMouseLeaveHandler, children: [_jsx(PortfolioBadge, { portfolio: portfolio, viewType: viewType, isHovered: selected || displayOverlay }), _jsx(CardContainer, { className: className, variant: variant, viewType: viewType, isSelected: selected, displayState: displayState, displayOverlay: displayOverlay, children: children }), displayState !== 'error' &&
                displayOverlay &&
                (_jsx(OverlayContentContainer, { style: {
                        opacity: opacity.to(value => value),
                        bottom: bottom.to(value => `${overlayBottomPosition * value}px`),
                        zIndex: isHovered ? 1 : 0,
                    }, children: _jsx(OverlayContent, { portfolio: portfolio, viewType: viewType }) }))] }));
};
export default PortfolioContainer;
